<template>
  <div v-if="$hasFonction(user, 'Voir véhicule carte importateur')">
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <div class="d-flex justify-content-end">
          <!-- bouton export start -->
          <b-button
            variant="outline-primary"
            size="sm"
            @click="ExportFomatExcel()"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Exporter
          </b-button>
          <!-- bouton export end -->

          <b-button
            variant="outline-primary"
            size="sm"
            class="ml-2"
            @click="ExportAllToFomatExcel()"
          >
            <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
            Tout Exporter
          </b-button>

          <!-- statut start -->
          <b-form-select
            v-model="selectedStatut"
            :options="statut_options"
            class="col-2 ml-2 container-statutfilter"
            @input="statutFiltre()"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                style="color: #b4b7bd; text-align: center"
                >-- statut --</b-form-select-option
              >
            </template>
          </b-form-select>
          <!-- statut end -->
        </div>

        <b-card no-body title="" class="mt-1">
          <div class="container-filter my-2 mx-2 row">
            <!-- search start -->
            <div style="width: 20%" class="container-search mr-2">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Recherche"
              >
              </b-form-input>
            </div>
            <!-- search end -->

            <!-- date start -->
            <div class="container-date-start mr-2" style="width: 18%">
                <b-form-input
                v-model="valueStart"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              @input="dateFiltre()"
              />
              <label>Date de début</label>
              <span
                class="text-danger"
                style="font-size: 0.9em; margin-top: 5px; margin-left: 8px"
              >
                {{ errorValueStart }}
              </span>
            </div>

            <div class="container-date-end mr-2" style="width: 16%">
                <b-form-input
                v-model="valueEnd"
              :date-format-options="dateFormatOptions"
              type="date"
              class="d-inline-block"
              @input="dateFiltre()"
              />
              <label>Date de fin</label>
            </div>
            <!-- date end -->

            <!-- select importateur start -->
            <b-form-select
              v-model="selectedImportateur"
              :options="imp_options"
              value-field="text"
              class="container-importateur col-2"
              @input="importateurFiltre()"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  style="color: #b4b7bd; text-align: center"
                  >-- Importateurs --</b-form-select-option
                >
              </template>
            </b-form-select>
            <!-- select importateur end -->

            <!-- solde start -->
            <div id="solde-input" class="container-solde ml-2 mt-2 mt-lg-0 mb-2 mb-lg-0" style="width: 20%">
              <b-form-input
                v-model="solde"
                type="text"
                disabled
                placeholder="Solde"
              >
              </b-form-input>
            </div>


            <div class="container-solde col-lg-2">
                <b-form-select
                            ref="status_filter"
                            v-model="selectedTrie"
                            :options="trieDate"
                            @change="changeTrie"
                            @input="filterValue"
                        >
                </b-form-select>
                <label>Trier Par Date</label>
            </div>
            <!-- solde end -->
          </div>

          <!-- table start -->
          <b-table
            v-model="excelVehicule"
            id="my-table"
            responsive
            :fields="headers"
            :items="vehicules"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            show-empty
            class="border-bottom position-relative"
          >
            <template #cell(date_arrivee)="data">
              <span>
                {{
                  data.item.manifeste.date_arrivee
                    ? data.item.manifeste.date_arrivee
                    : "----"
                }}
              </span>
            </template>

            <template #cell(created_at)="data">
              <span>
                {{ data.item.created_at ? data.item.created_at : "----" }}
              </span>
            </template>

            <template #cell(numero_manif)="data">
              <span>
                {{
                  data.item.manifeste.numero_manif
                    ? data.item.manifeste.numero_manif
                    : "----"
                }}
              </span>
            </template>
            <template #cell(prix_carte_imp)="data">
              {{ $thousandSeparator(data.item.prix_carte_imp) }}
            </template>

            <!-- iconAction start -->
            <template #cell(actions)="data">
              <div class="row">
                <div
                  v-if="
                    $hasFonction(user, 'Impression carte importateur vehicule')
                  "
                >
                  <b-button
                    :id="'button-1' + data.item.id"
                    size="sm"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="printVehicule(data.item)"
                  >
                    <feather-icon icon="PrinterIcon" />
                  </b-button>
                  <!-- <b-tooltip
                    :target="'button-1' + data.item.id"
                    title="Imprimer"
                    variant="dark"
                  ></b-tooltip> -->
                </div>

                <div
                  v-if="
                    $hasFonction(user, 'Modifier véhicule carte importateur')
                  "
                >
                  <b-button
                    :id="'button-2' + data.item.id"
                    size="sm"
                    variant="flat-warning"
                    class="btn-icon"
                    @click="editVehicule(data.item)"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>
                  <!-- <b-tooltip
                    :target="'button-2' + data.item.id"
                    title="Editer"
                    variant="dark"
                  ></b-tooltip> -->
                </div>

                <!-- <div> -->
                <div
                  v-if="
                    $hasFonction(user, 'Supprimer véhicule carte importateur')
                  "
                >
                  <b-button
                    v-if="
                      data.item.manifeste.status == '1' &&
                      !optionAnnulerVente(data.item)
                    "
                    :id="'button-3' + data.item.id"
                    size="sm"
                    variant="flat-success"
                    class="btn-icon"
                    @click="sellVehicule(data.item)"
                  >
                    <b-icon-cart-plus></b-icon-cart-plus>
                  </b-button>
                  <b-tooltip
                    placement="left"
                    :target="'button-3' + data.item.id"
                    title="Vendre"
                    variant="dark"
                  ></b-tooltip>
                  <span id="icon-proforma">
            <feather-icon
                v-if="
                      data.item.manifeste.status == '1' &&
                      !optionAnnulerVente(data.item)
                    "
                icon="FileTextIcon"
                size="18"
                class="iconFeather"
                @click="newProforma(data.item)"
            />
          <b-tooltip
                         target="icon-proforma"
                         title="Proforma"
                         variant="dark"
           ></b-tooltip>
          </span>
                </div>

                <div
                  v-if="
                    $hasFonction(user, 'Supprimer véhicule carte importateur')
                  "
                >
                  <b-button
                    :id="'button-4' + data.item.id"
                    v-if="optionAnnulerVente(data.item)"
                    size="sm"
                    variant="flat-danger"
                    class="btn-icon"
                    @click="annulationVente(data.item)"
                  >
                    <!-- <feather-icon icon="ShoppingCartIcon" /> -->
                    <b-icon-cart-plus></b-icon-cart-plus>
                  </b-button>
                  <b-tooltip
                    placement="left"
                    :target="'button-4' + data.item.id"
                    title="Annuler la vente"
                    variant="dark"
                  ></b-tooltip>
                </div>

                <div
                  v-if="
                    $hasFonction(user, 'Supprimer véhicule carte importateur')
                  "
                >
                  <b-button
                    :id="'button-5' + data.item.id"
                    v-if="!data.item.manifeste.date_arrivee"
                    size="sm"
                    variant="flat-danger"
                    class="btn-icon"
                    @click="deleteVehicule(data.item)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                  <!-- <b-tooltip
                    :target="'button-5' + data.item.id"
                    title="Supprimer"
                    variant="dark"
                  ></b-tooltip> -->
                </div>
              </div>
            </template>
            <!-- iconAction end -->

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2" v-if="totalRows != 0"> 1 - <span v-if="perPage >= totalRows">{{ totalRows }}</span>
	              <span v-if="perPage < totalRows"> {{perPage}} </span> sur {{ totalRows }}
                </span>
              <span class="text-nowrap ml-2" v-if="totalRows == 0"> 0 éléments</span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>

        <!-- modal add client start  -->
        <b-modal
          id="modal-addClient"
          v-model="modalAddClient"
          title="Ajouter un nouveau client"
          @hidden="resetModalAddClient"
          hide-footer
          centered
        >
          <!-- form start -->
          <b-form ref="addNewClient" @submit.prevent>
            <b-form-group>
              <validation-provider #default="{ errors }" name="name">
                <label for="name">Nom et Prénom</label>
                <b-form-input
                  id="name"
                  placeholder="Nom et Prénom"
                  type="text"
                  v-model="newClient.name"
                  :state="errors.length > 0 ? false : null"
                  @click="resetInputNameModal"
                />
                <small class="text-danger">
                  {{ errors[0] ? errors[0] : errorsNameModal }}
                </small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required"
              >
                <label for="phone">Téléphone</label>
                <b-form-input
                  id="phone"
                  type="tel"
                  v-model="newClient.phone"
                  placeholder="Téléphone"
                  :state="errors.length > 0 ? false : null"
                  @click="resetInputPhoneModal"
                />
                <small class="text-danger">
                  {{ errors[0] ? errors[0] : errorsPhoneModal }}
                </small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider #default="{ errors }" name="ifu">
                <label for="ifu">Ifu</label>
                <b-form-input
                  id="ifu"
                  type="text"
                  v-model="newClient.ifu"
                  placeholder="Ifu"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{ errors[0] ? errors[0] : errorsIfuModal }}
                </small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider #default="{ errors }" name="reccm">
                <label for="reccm">Reccm</label>
                <b-form-input
                  id="reccm"
                  type="text"
                  v-model="newClient.reccm"
                  placeholder="Reccm"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{ errors[0] ? errors[0] : errorsReccmModal }}
                </small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="modalAddClient = false"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButtonAddClient"
                variant="primary"
                class="mt-2"
                @click="addNewClient"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
          <!-- form end -->
        </b-modal>
        <!-- modal add client end -->

        <!-- modal print start  -->
        <b-modal
          id="printVehicule-modal"
          v-model="printVehiculeModal"
          title="Impression de carte"
          @hidden="printVehiculeModalReset"
          hide-footer
          centered
        >
          <!-- form start -->
          <b-form
            class="mt-1"
            ref="printForm"
            @submit.stop.prevent="printHandleSubmit"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="bl"
                rules="required"
              >
                <label for="bl">BL</label>
                <b-form-input
                  id="bl"
                  type="text"
                  v-model="printForm.bl"
                  placeholder="BL"
                  @input="blInputPrintReset"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? errors[0] : blPrintModalErrors
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="numero_chassis"
                rules="required"
              >
                <label for="numero_chassis">Numéro Chassis</label>
                <b-form-input
                  id="numero_chassis"
                  type="text"
                  disabled
                  v-model="printForm.numero_chassis"
                  placeholder="Numéro Chassis"
                  @input="numeroChassisInputPrintReset"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? errors[0] : numero_chassisPrintModalErrors
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="montant"
                rules="required"
              >
                <label for="montant">Montant</label>
                <cleave
                  id="montant"
                  v-model="printForm.montant"
                  class="form-control"
                  :raw="false"
                  placeholder="0"
                  :options="numberFormat"
                  @input="montantInputPrintReset"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? errors[0] : montantPrintModalErrors
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="printVehiculeModal = false"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButtonPrintVehicule"
                variant="primary"
                class="mt-2"
                @click="printHandleOk"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
          <!-- form end -->
        </b-modal>
        <!-- modal print end-->

        <!-- modal edit start  -->
        <b-modal
          id="editVehicule-modal"
          v-model="editVehiculeModal"
          title="Modifier véhicule"
          @hidden="editVehiculeModalReset"
          hide-footer
          centered
        >
          <!-- form start -->
          <b-form
            class="mt-1"
            ref="editForm"
            @submit.stop.prevent="editHandleSubmit"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="numero_chassis"
                rules="required"
              >
                <label for="numero_chassis">Numéro Chassis</label>
                <b-form-input
                  id="numero_chassis"
                  type="text"
                  v-model="editForm.numero_chassis"
                  placeholder="Numéro Chassis"
                  @input="numero_chassisInputEditReset"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? errors[0] : numero_chassisEditModalErrors
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="marque"
                rules="required"
              >
                <label for="marque">Marque</label>
                <model-list-select
                :list="marquesList"
                id="marque"
                v-model="editForm.marque"
                option-value="id"
                option-text="name"
                  @click="marqueSelectEditReset"
                :state="errors.length > 0 ? false : null"
              />
                <small class="text-danger">{{
                  errors[0] ? errors[0] : marqueEditModalErrors
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="couleur"
                rules="required"
              >
                <label for="couleur">Couleur</label>
                <model-list-select
                :list="couleursList"
                id="couleur"
                v-model="editForm.couleur"
                option-value="id"
                option-text="name"
                  @input="couleurSelectEditReset"
                :state="errors.length > 0 ? false : null"
              />
                <small class="text-danger">{{
                  errors[0] ? errors[0] : couleurEditModalErrors
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="annee"
                rules="required"
              >
                <label for="annee">Année</label>
                <b-form-input
                  id="annee"
                  type="number"
                  v-model="editForm.annee"
                  placeholder="Année"
                  @input="anneeInputEditReset"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? errors[0] : anneeEditModalErrors
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="editVehiculeModal = false"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButtonEditVehicule"
                variant="primary"
                class="mt-2"
                @click="editHandleOk"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
          <!-- form end -->
        </b-modal>
        <!-- modal edit end -->
        <!-- Modal des proforma -->
        <b-modal
            size="lg"
            id="proforma-modal"
            modal-class="modal-primary"
            v-model="dialogProforma"
            title="Enregistrer un proforma"
            @hidden="resetProforma"
            :ok-disabled="isOperating"
            hide-footer
            centered
        >
          <!-- form start -->
          <b-form class="mt-1" ref="venteForm" @submit.stop.prevent="saveProforma">
            <div>
              <form-wizard
                  ref="form_wizard"
                  :validateOnBack="true"
                  back-button-text="Précédent"
                  nextButtonText="Suivant"
                  color="#27295c"
                  :title="null"
                  :subtitle="null"
                  @on-complete="saveProforma"
                  class="steps-transparent mb-3"
              >
                <!-- client tab -->
                <tab-content title="Client" :before-change="validationFormClient">
                  <validation-observer ref="clientRules" tag="form">
                    <b-row>
                      <b-col md="4">
                        <b-form-group label="Client" label-for="clientName">
                          <validation-provider
                              #default="{ errors }"
                              name="clientName"
                          >
                            <!-- <b-form-select
                              id="clientName"
                              v-model="sellForm.client"
                              :options="clientsList"
                              value-field="id"
                              text-field="name"
                              @input="client_nameSelectSellReset"
                              :state="errors.length > 0 ? false : null"
                            /> -->
                            <Select2
                                id="clientNom"
                                :options="[].slice.call(clients).map(_c => { return {value: _c.id, text: _c.name} })"
                                dropDownParent="#proforma-modal___BV_modal_content_"
                                lang="fr"
                                :change="checkClientData"
                            />
                            <!-- <b-form-input
                              list="clientsList"
                              id="clientName"
                              v-model="sellForm.client"
                              @change="client_nameSelectSellReset()"
                              :state="errors.length > 0 ? false : null"
                            />
                            <b-form-datalist id="clientsList" :options="clientsList"></b-form-datalist> -->
                            <small class="text-danger">{{
                                errors[0]
                              }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col class="px-0 mt-2 col-2">
                        <b-button
                            variant="outline-dark"
                            size="md"
                            class=""
                            @click="modalAddClient = true"
                        >
                          Nouveau
                        </b-button>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                            label="Téléphone"
                            label-for="clientPhone"
                        >
                          <validation-provider
                              #default="{ errors }"
                              name="clientPhone"
                              rules=""
                          >
                            <b-form-input
                                id="clientPhone"
                                v-model="client_phone"
                                @keyup="checkClientDataPhone()"
                                type="text"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Téléphone"
                            />
                            <small class="text-danger">{{
                                errors[0]
                              }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group label="Ifu" label-for="clientIfu">
                          <validation-provider
                              #default="{ errors }"
                              name="clientIfu"
                              rules=""
                          >
                            <b-form-input
                                id="clientIfu"
                                v-model="client_ifu"
                                type="text"
                                :disabled="true"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Ifu"
                            />
                            <small class="text-danger">{{
                                errors[0]
                              }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group label="Reccm" label-for="clientReccm">
                          <validation-provider
                              #default="{ errors }"
                              name="clientReccm"
                              rules=""
                          >
                            <b-form-input
                                id="clientReccm"
                                v-model="client_reccm"
                                type="text"
                                disabled="true"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Reccm"
                            />
                            <small class="text-danger">{{
                                errors[0]
                              }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </validation-observer>
                </tab-content>

                <!-- signataire tab  -->
                <tab-content
                    title="Signataire"
                    :before-change="validationFormSignataire"
                >
                  <validation-observer ref="signataireRules" tag="form">
                    <b-row>
                      <b-col md="6">
                        <b-form-group class="col pr-0">
                          <validation-provider
                              #default="{ errors }"
                              name="signataireName"
                              rules="required"
                          >
                            <label for="signataireName">Nom signataire</label>
                            <b-form-select
                                id="signataire"
                                v-model="signataire"
                                :options="signataires"
                                value-field="name"
                                text-field="name"
                                @change="checkSignatairesData"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <!--                    <b-form-group
                                              label="Nom signataire"
                                              label-for="signataireName"
                                            >
                                              <validation-provider
                                                #default="{ errors }"
                                                name="signataireName"
                                                rules="required"
                                              >
                                                <b-form-select
                                                    ref="signataires"
                                                    v-model="venteForm.signataire"
                                                    :options="signataires"
                                                    value-field="name"
                                                    text-field="name"
                                                    @change="checkSignatairesData()"
                                                    :state="errors.length > 0 ? false : null"
                                                >
                                                </b-form-select>
                                                <small class="text-danger">{{ errors[0] }}</small>
                                              </validation-provider>
                                            </b-form-group>-->
                      </b-col>

                      <b-col md="6">
                        <b-form-group label="Téléphone" label-for="signatairePhone">
                          <validation-provider
                              #default="{ errors }"
                              name="signatairePhone"
                              rules=""
                          >
                            <b-form-input
                                id="signatairePhone"
                                type="number"
                                value-field="name"
                                text-field="name"
                                @keyup="checkSignatairesDataPhone()"
                                v-model="signataire_phone"
                                :state="errors.length > 0 ? false : null"
                                placeholder="Téléphone"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </validation-observer>
                </tab-content>

                <!-- details du paiement -->
                <tab-content
                    title="Paiement"
                    :before-change="validationFormDetails"
                >
                  <validation-observer ref="detailsRules" tag="form">
                    <b-row>
                      <b-col md="12">
                        <b-form-group label="Prix de vente" label-for="prix_vente">
                          <validation-provider
                              #default="{ errors }"
                              name="prix_vente"
                              rules="required"
                          >
                            <cleave
                                class="form-control"
                                :raw="false"
                                id="prix_vente"
                                :options="numberFormat"
                                placeholder="0"
                                v-model="proformaForm.prix_vente"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </validation-observer>
                </tab-content>
              </form-wizard>
            </div>

            <b-form-group class="mb-0">
              <b-button
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2 mr-1"
                  @click="dialogProforma = false"
              >
                Annuler
              </b-button>

              <b-button
                  v-if="etatButtonProforma"
                  variant="primary"
                  class="mt-2"
                  @click="saveProforma"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                    animation="spin"
                    font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
        </b-modal>
        <!-- modal sell start -->
        <b-modal
          id="sellVehicule-modal"
          v-model="sellVehiculeModal"
          size="lg"
          title="Vendre véhicule"
          centered
          @hidden="sellVehiculeResetModal"
        >
          <template #modal-footer>
            <b-button
              variant="outline-secondary"
              class="mr-1 float-right"
              @click="sellVehiculeModal = false"
            >
              Annuler
            </b-button>
            <b-button
              v-if="etatButtonSellVehicule"
              variant="primary"
              class="float-right"
              @click="sellHandleOk"
            >
              Enregistrer
            </b-button>

            <b-button v-else variant="primary" class="mt-2">
              <b-icon-arrow-clockwise
                animation="spin"
                font-scale="1"
              ></b-icon-arrow-clockwise>
              En cours
            </b-button>
          </template>

          <!-- form start -->
          <b-form
            class="mt-1"
            ref="sellForm"
            @submit.stop.prevent="sellHandleSubmit"
          >
            <b-row>
              <b-col cols="12">
                <div>
                  <form-wizard
                    ref="form_wizard"
                    color="#27295c"
                    :title="null"
                    :subtitle="null"
                    shape="square"
                    :validateOnBack="true"
                    back-button-text="Précédent"
                    nextButtonText="Suivant"
                    class="mb-3"
                    @on-complete="formSubmitted"
                  >
                    <!-- client tab -->
                    <tab-content title="Client" :before-change="validationFormClient">
                      <validation-observer ref="clientRules" tag="form">
                        <b-row>
                          <b-col md="4">
                            <b-form-group label="Client" label-for="clientName">
                              <validation-provider
                                  #default="{ errors }"
                                  name="clientName"
                              >
                                <!-- <b-form-select
                                  id="clientName"
                                  v-model="sellForm.client"
                                  :options="clientsList"
                                  value-field="id"
                                  text-field="name"
                                  @input="client_nameSelectSellReset"
                                  :state="errors.length > 0 ? false : null"
                                /> -->
                                <Select2
                                    id="clientNom"
                                    :options="[].slice.call(clients).map(_c => { return {value: _c.id, text: _c.name} })"
                                    dropDownParent="#sellVehicule-modal___BV_modal_content_"
                                    lang="fr"
                                    :change="client_nameSelectSellReset"
                                />
                                <!-- <b-form-input
                                  list="clientsList"
                                  id="clientName"
                                  v-model="sellForm.client"
                                  @change="client_nameSelectSellReset()"
                                  :state="errors.length > 0 ? false : null"
                                />
                                <b-form-datalist id="clientsList" :options="clientsList"></b-form-datalist> -->
                                <small class="text-danger">{{
                                    errors[0]
                                        ? errors[0]
                                        : client_nameSellModalErrors
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <b-col class="px-0 mt-2 col-2">
                            <b-button
                                variant="outline-dark"
                                size="md"
                                class=""
                                @click="modalAddClient = true"
                            >
                              Nouveau
                            </b-button>
                          </b-col>

                          <b-col md="6">
                            <b-form-group
                                label="Téléphone"
                                label-for="clientPhone"
                            >
                              <validation-provider
                                  #default="{ errors }"
                                  name="clientPhone"
                                  rules=""
                              >
                                <b-form-input
                                    id="clientPhone"
                                    v-model="sellForm.client_phone"
                                    @keyup="checkClientDataPhone()"
                                    type="text"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Téléphone"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <b-col md="6">
                            <b-form-group label="Ifu" label-for="clientIfu">
                              <validation-provider
                                  #default="{ errors }"
                                  name="clientIfu"
                                  rules=""
                              >
                                <b-form-input
                                    id="clientIfu"
                                    v-model="sellForm.client_ifu"
                                    type="text"
                                    :disabled="true"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Ifu"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <b-col md="6">
                            <b-form-group label="Reccm" label-for="clientReccm">
                              <validation-provider
                                  #default="{ errors }"
                                  name="clientReccm"
                                  rules=""
                              >
                                <b-form-input
                                    id="clientReccm"
                                    v-model="sellForm.client_reccm"
                                    type="text"
                                    disabled="true"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Reccm"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </validation-observer>
                    </tab-content>


                    <!-- deposant tab -->
                    <tab-content
                      title="Déposant"
                      :before-change="validationFormDeposant"
                    >
                      <validation-observer ref="deposantRules" tag="form">
                        <b-row>
                          <b-col md="6">
                            <b-form-group
                              label="Nom et Prénom"
                              label-for="deposantName"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="deposantName"
                                rules=""
                              >
                                <b-form-input
                                  id="deposantName"
                                  type="text"
                                  v-model="sellForm.deposant_name"
                                  placeholder="Nom et Prénom"
                                  :disabled="activeCheckBox == 1 ? false : true"
                                  :state="errors.length > 0 ? false : null"
                                  @input="deposant_nameInputSellReset"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                    ? errors[0]
                                    : deposant_nameSellModalErrors
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group
                              label="Téléphone"
                              label-for="deposantPhone"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="deposantPhone"
                                rules=""
                              >
                                <b-form-input
                                  id="deposantPhone"
                                  type="number"
                                  v-model="sellForm.deposant_phone"
                                  :state="errors.length > 0 ? false : null"
                                  placeholder="Téléphone"
                                  :disabled="activeCheckBox == 1 ? false : true"
                                  @input="deposant_phoneInputSellReset"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                    ? errors[0]
                                    : deposant_phoneSellModalErrors
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <div class="mb-1">
                          <span>Existe t'il un déposant ? </span>
                          <b-form-checkbox
                            class="ml-2"
                            style="padding-top: 3px"
                            switch
                            inline
                            value="1"
                            unchecked-value="0"
                            name="check-button"
                            v-model="activeCheckBox"
                            @change="inputDeposantReset"
                          >
                          </b-form-checkbox>
                        </div>
                      </validation-observer>
                    </tab-content>

                    <!-- signataire tab  -->
                    <tab-content
                        title="Signataire"
                        :before-change="validationFormSignataire"
                    >
                      <validation-observer ref="signataireRules" tag="form">
                        <b-row>
                          <b-col md="6">
                            <b-form-group class="col pr-0">
                              <validation-provider
                                  #default="{ errors }"
                                  name="signataireName"
                                  rules="required"
                              >
                                <label for="signataireName">Nom signataire</label>
                                <b-form-select
                                    id="signataire"
                                    v-model="sellForm.signataire"
                                    :options="signataires"
                                    value-field="name"
                                    text-field="name"
                                    @change="signataire_nameSelectSellReset()"
                                    :state="errors.length > 0 ? false : null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                            <!--                    <b-form-group
                                                  label="Nom signataire"
                                                  label-for="signataireName"
                                                >
                                                  <validation-provider
                                                    #default="{ errors }"
                                                    name="signataireName"
                                                    rules="required"
                                                  >
                                                    <b-form-select
                                                        ref="signataires"
                                                        v-model="venteForm.signataire"
                                                        :options="signataires"
                                                        value-field="name"
                                                        text-field="name"
                                                        @change="checkSignatairesData()"
                                                        :state="errors.length > 0 ? false : null"
                                                    >
                                                    </b-form-select>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                  </validation-provider>
                                                </b-form-group>-->
                          </b-col>

                          <b-col md="6">
                            <b-form-group label="Téléphone" label-for="signatairePhone">
                              <validation-provider
                                  #default="{ errors }"
                                  name="signatairePhone"
                                  rules=""
                              >
                                <b-form-input
                                    id="signatairePhone"
                                    type="number"
                                    value-field="name"
                                    text-field="name"
                                    @keyup="checkSignatairesDataPhone()"
                                    v-model="sellForm.signataire_phone"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Téléphone"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </validation-observer>
                    </tab-content>


                    <!-- details du paiement -->
                    <tab-content
                      title="Détails paiement"
                      :before-change="validationFormDetails"
                    >
                      <validation-observer ref="detailsRules" tag="form">
                        <b-row>
                          <b-col md="6">
                            <b-form-group
                              label="Type de vente"
                              label-for="typeVente"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="typeVente"
                                rules=""
                              >
                                <b-form-select
                                  id="typeVente"
                                  v-model="sellForm.type_vente"
                                  :options="typeVenteList"
                                  @input="type_venteSelectSellReset"
                                  :state="errors.length > 0 ? false : null"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                    ? errors[0]
                                    : type_venteSellModalErrors
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <b-col md="6">
                            <b-form-group
                              label="Montant payé"
                              label-for="montant_paye"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="montant_paye"
                                rules="required"
                              >
                                <cleave
                                  id="montant_paye"
                                  v-model="sellForm.montant_paye"
                                  class="form-control"
                                  :raw="false"
                                  placeholder="0"
                                  :options="numberFormat"
                                  @input="montant_payeInputSellReset"
                                  :state="errors.length > 0 ? false : null"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                    ? errors[0]
                                    : montant_payeSellModalErrors
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </validation-observer>
                    </tab-content>
                  </form-wizard>
                </div>
              </b-col>
            </b-row>
          </b-form>
          <!-- form end -->
        </b-modal>
        <!-- modal sell end-->
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import { mapState } from "vuex";
import Cleave from "vue-cleave-component";
import { ModelListSelect } from "vue-search-select";
// import { FormWizard, TabContent } from 'vue-form-wizard'
import FormWizard from "@core/components/vue-form-wizard/src/components/FormWizard.vue";
import TabContent from "@core/components/vue-form-wizard/src/components/TabContent.vue";
import "@core/components/vue-form-wizard/dist/vue-form-wizard.min.css";
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import Select2 from "@/components/Select2.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import { codeIcon } from "./code";
import {
  BIconPlus,
  BCard,
  BTable,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BFormDatalist,
  BTooltip,
  BIconArrowClockwise,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  BIconCircleFill,
  BIconCartPlus,
  VBTooltip,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { dataStyleTask } from 'echarts/lib/visual/style';

export default {
  components: {
    ModelListSelect,
    FormWizard,
    TabContent,
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BFormDatalist,
    EmptyList,
    BIconPlus,
    BIconArrowClockwise,
    BRow,
    BCol,
    BCard,
    Cleave,
    BButton,
    BModal,
    BForm,
    BTooltip,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BIconCartPlus,
    BFormDatepicker,
    DatePicker,
    BIconCircleFill,
    BFormSelectOption,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
    Select2
  },
  data() {
    return {
      newClient: {},
      modalAddClient: false,
      etatButtonAddClient: true,
      errorsNameModal: null,
      errorsPhoneModal: null,
      errorsIfuModal: null,
      errorsReccmModal: null,
      client: null,
      client_reccm: null,
      client_ifu: null,
      client_phone: null,
      signataire: null,
      signataires: [],
      signataire_phone: null,
      activeCheckBox: 0,
      etatButtonEditVehicule: true,
      etatButtonPrintVehicule: true,
      etatButtonProforma: true,
      etatButtonSellVehicule: true,
      dateFormatOptions: { day: "numeric", month: "long", year: "numeric" },
      statut_options: [
        { text: "Imprimée", value: "Imprimée" },
        { text: "Non Imprimée", value: "Non Imprimée" },
      ],
      numberFormat: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        delimiter: " ",
      },
      typeVenteList: [
        { text: "Transit", value: "Transit" },
        { text: "Local", value: "Local" },
      ],
      imp_options: [],
      marquesList: [],
      couleursList: [],
      clientsList: [],
      clients: [],
      signatairesList: [],
      // solde: null,
      valueStart: null,
      valueEnd: null,
      errorValueStart: "",
      proformaForm: {},
      hidden: true,
      hiddenEmpty: true,
      isOperating: false,
      hiddenLoader: false,
      selectedStatut: null,
      vehiculecancel: {},
      societeObjet: {},
      selectedImportateur: null,
      perPage: 10,
      currentPage: 1,
      filter: "",
      pageOptions: [10, 25, 50, 100, { value: 1000000, text: "Tout" }],
      vehicules: [],
      vehiculesList: [],
      headers: [
        {
          key: "created_at",
          label: "Date Enregistrement",
          sortable: false,
          // class: 'text-center'
        },
        {
          key: "date_arrivee",
          label: "Date d'arrivée",
          sortable: false,
          // class: 'text-center'
        },
        {
          key: "numero_manif",
          label: "N. Manifeste",
          sortable: true,
        },
        {
          key: "importateur",
          label: "Importateur",
          sortable: true,
        },
        {
          key: "numero_chassis",
          label: "N. Chassis",
          sortable: true,
        },
        {
          key: "marque",
          label: "Marque",
          sortable: true,
        },
        {
          key: "couleur",
          label: "Couleur",
          sortable: true,
        },
        {
          key: "annee",
          label: "Année",
          sortable: true,
        },
        {
          key: "prix_carte_imp",
          label: "P.Carte importateur",
          sortable: true,
          // class: 'text-center',
        },
        {
          key: "statut",
          label: "Statut",
          sortable: true,
          // class: 'text-center',
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
        },
      ],
      trieDate: [
        { text: "Croissant", value: "croissant" },
        { text: "Decroissant", value: "decroissant" },
      ],
      selectedTrie: null,
      excelVehicule: [],
      nameState: null,
      printVehiculeModal: false,
      editVehiculeModal: false,
      sellVehiculeModal: false,
      blPrintModalErrors: null,
      numero_chassisPrintModalErrors: null,
      montantPrintModalErrors: null,
      dialogProforma: false,
      numero_chassisEditModalErrors: null,
      marqueEditModalErrors: null,
      couleurEditModalErrors: null,
      anneeEditModalErrors: null,
      client_nameSellModalErrors: null,
      client_phoneSellModalErrors: null,
      client_ifuSellModalErrors: null,
      client_reccmSellModalErrors: null,
      deposant_nameSellModalErrors: null,
      deposant_phoneSellModalErrors: null,
      signataire_nameSellModalErrors: null,
      signataire_phoneSellModalErrors: null,
      type_venteSellModalErrors: null,
      montant_payeSellModalErrors: null,
      printForm: {
        id: 0,
        bl: "",
        numero_chassis: "",
        montant: 0,
      },
      editForm: {
        id: 0,
        numero_chassis: "",
        marque: "",
        couleur: "",
        annee: "",
      },
      sellForm: {
		client: '',
		client_phone: '',
		client_ifu: '',
		client_reccm: '',
        invoice_type: "Facture normalisée",
      },
      standardizedInvoiceGenerated: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.auth,
    }),

    totalRows() {
      // Set the initial number of items
      return this.vehicules.length;
    },

    solde() {
      var solde = 0;

      this.vehicules.forEach((element) => {
        solde += element.prix_carte_imp;
      });

      return this.thousandSeparator(solde);
    },
  },
  created() {
    this.getVehicules();
    this.getImportateurs();
    this.getMarques();
    this.getCouleurs();
    this.getClients();
    this.getSignataires();
    this.getSociete();
    this.selectedTrie = "decroissant"
  },
  methods: {

    addNewClient() {
      this.etatButtonAddClient = false;

      this.$http
        .post("/clients", this.newClient)
        .then((result) => {
          if (result.data.success) {
            this.etatButtonAddClient = true;
            this.resetInputModal();
            this.newClient = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
            this.showToast("Client ajouté avec succès", "success");
            this.getClients();
            this.modalAddClient = false;
          } else {
            this.etatButtonAddClient = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButtonAddClient = true;
          var err = e.response.data.errors;

          if (err) {
            if (err.name) {
              this.errorsNameModal = err.name[0];
            }

            if (err.phone) {
              this.errorsPhoneModal = err.phone[0];
            }

            if (err.ifu) {
              this.errorsIfuModal = err.ifu[0];
            }

            if (err.reccm) {
              this.errorsReccmModal = err.reccm[0];
            }
          }
        });
    },

    resetModalAddClient() {
      this.newClient.name = "";
      this.newClient.phone = "";
      this.newClient.ifu = "";
      this.newClient.reccm = "";
      this.resetInputModal();
    },

    resetInputModal() {
      this.resetInputNameModal();
      this.resetInputPhoneModal();
      this.resetInputIfuModal();
      this.resetInputReccmModal();
    },

    resetInputNameModal() {
      if (this.errorsNameModal) {
        this.errorsNameModal = null;
      }
    },

    resetInputPhoneModal() {
      if (this.errorsPhoneModal) {
        this.errorsPhoneModal = null;
      }
    },
    saveProforma(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isOperating = true;
      this.proformaForm.prix_vente = this.formatAmount(
          this.proformaForm.prix_vente
      );

      this.proformaForm.client = this.client;
      this.proformaForm.client_reccm = this.client_reccm;
      this.proformaForm.client_ifu = this.client_ifu;
      this.proformaForm.client_phone = this.client_phone;
      this.proformaForm.signataire = this.signataire;
      this.proformaForm.signataire_phone = this.signataire_phone;

      this.etatButtonProforma = false;

      this.$http
          .post("/pro-factures", this.proformaForm)
          .then((result) => {
            this.isOperating = false;
            if (result.data.success) {
              this.etatButtonProforma = true;
              let id = result.data.data.id;
              let routeData = this.$router.resolve({
                name: "proforma-show",
                params: { id: id },
              });
              window.open(routeData.href, "_blank");
              this.showToast("Proforma enregistré avec succès", "success");
              this.resetProforma();
              this.vehiculeData();
              this.resetClientFields();
            } else {
              this.etatButtonProforma = true;
              this.showToast(result.data.message, "danger");
            }
          })
          .catch((e) => {
            this.etatButtonProforma = true;
            this.isOperating = false;
            var err = e.response.data.message;
            this.showToast(err, "danger");
          });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    formatAmount(amount) {
      amount = this.$thousandFormater(amount);
      return amount;
    },
    checkClientData(e) {
      const _id = e.target.value;
      const selected = this.clients.find(_c => _c.id == _id);
      if (selected) {
        this.client = selected
        this.client_reccm = selected.reccm;
        this.client_ifu = selected.ifu;
        this.client_phone = selected.phone;
      }
    },
    checkClientDataPhone() {
        let chosen;
      if (this.sellForm.client_phone) {
          chosen = this.clients.find(({ phone }) => phone === this.sellForm.client_phone);
      }
      //   console.log("checkClientDataPhone checkClientDataPhone")
        console.log(chosen)

        if (chosen) {
        let data = {
          value: chosen.id,
          text: chosen.name
        };
        let newOption = new Option(data.text, data.value, false, true);
        $('#clientNom').append(newOption).trigger('change');
          this.sellForm.client = chosen.name;
          this.sellForm.client_reccm = chosen.reccm;
          this.sellForm.client_ifu = chosen.ifu;
            console.log(this.sellForm.client)
      } else {
      }
    },

    checkSignatairesDataPhone() {
      let chosen;
      if (this.sellForm.signataire_phone) {
        chosen = this.signataires.find(
            ({ phone }) => phone === this.sellForm.signataire_phone
        );
      }
      if (chosen) {
        this.sellForm.signataire = chosen.name;
      }
    },

    checkSignatairesData() {
      if (this.signataire) {
        let chosen = this.signataires.find(
            ({ text }) => text === this.signataire
        );
        if (chosen) {
          this.signataire_phone = chosen.phone;
          this.signataire = chosen.name;
          let item = this.vehicule;
          /*  if (this.Isimportateur===0 ){
              this.newvoice=this.factureTypes[1].value;
            }else{
              this.newvoice=this.factureTypes[0].value;
            }*/
          return (this.sellForm = {
            id: item.id,
            manifeste: item.manifeste,
            prix_vente: item.prix_vente,
            prix_revient: item.prix_revient,
            societe: item.societe,
            marque: item.marque,
            numero_chassis: item.numero_chassis,
            annee: item.annee,
            couleur: item.couleur,
            traitement_special: "0",
            venteType: "Transit",
            invoice_type: this.newvoice,
            client: this.sellForm.client,
            client_reccm: this.sellForm.reccm,
            client_ifu: this.sellForm.ifu,
            client_phone: this.sellForm.phone,
            signataire: this.sellForm.signataire,
            signataire_phone: this.sellForm.signataire_phone,
          });

        }
      } else {
        this.signataire_phone = "";
      }
    },

    resetInputIfuModal() {
      if (this.errorsIfuModal) {
        this.errorsIfuModal = null;
      }
    },

    resetInputReccmModal() {
      if (this.errorsReccmModal) {
        this.errorsReccmModal = null;
      }
    },

    validationFormClient() {
      return new Promise((resolve, reject) => {
        this.$refs.clientRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormDeposant() {
      return new Promise((resolve, reject) => {
        this.$refs.deposantRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormSignataire() {
      return new Promise((resolve, reject) => {
        this.$refs.signataireRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.detailsRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    thousandSeparator(amount) {
      if (
        amount !== "" &&
        amount !== undefined &&
        amount !== 0 &&
        amount !== "0" &&
        amount !== null
      ) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        return amount;
      }
    },

    inputDeposantReset() {
      if (this.activeCheckBox != 1) {
        this.sellForm.deposant_name = "";
        this.sellForm.deposant_phone = "";
      }
    },

    sellVehiculeResetModal() {
      this.sellForm.client = "";
      this.sellForm.client_phone = "";
      this.sellForm.client_ifu = "";
      this.sellForm.client_reccm = "";
      this.sellForm.deposant_name = "";
      this.sellForm.deposant_phone = "";
      this.sellForm.signataire = "";
      this.sellForm.signataire_phone = "";
      this.sellForm.type_vente = "";
      this.sellForm.montant_paye = "";
    },

    formSubmitted() {
      console.log(this.sellForm);
    },

    getImportateurs() {
      this.$http
        .get("/show-importateurs")
        .then((res) => {
          res.data.data.reverse().forEach((element) => {
            var obj = {
              value: element.id,
              text: element.name,
            };

            this.imp_options.push(obj);
          });
        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },

    getVehicules() {
      this.$http
        .get("/carte-importateur/vehicules")
        .then((res) => {
          if (res.status) {
            this.hiddenLoader = true;
          }

          this.vehicules = res.data.data.reverse();
          this.vehiculesList = res.data.data.reverse();

          this.vehicules.sort(function(a,b){
            return new Date(b.created_at) - new Date(a.created_at);
          });


          if (this.vehicules.length) {
            this.hidden = false;
            this.hiddenEmpty = true;
          } else {
            this.hidden = true;
            this.hiddenEmpty = false;
          }
        })
        .catch((e) => {
          console.log("erreur de récupération:" + e.response.data.message);
        });
    },

    getMarques() {
      this.$http
        .get("/marques")
        .then((res) => {
          this.marquesList = res.data.data.reverse();
        })
        .catch((e) => {
          console.log("erreur marqueList:" + e.response.data.message);
        });
    },
    async getSociete() {
      await this.$http.get("/societes").then((response) => {
        this.societeObjet = response.data.data[0];
        this.Isimportateur= this.societeObjet.importateur

      });
    },
    getCouleurs() {
      this.$http
        .get("/couleurs")
        .then((res) => {
          this.couleursList = res.data.data.reverse();
        })
        .catch((e) => {
          console.log("erreur couleurList:" + e.response.data.message);
        });
    },
    newProforma(item) {
      console.log(item)
      let toForm = {
        id: item.id,
        manifeste: item.manifeste,
        prix_vente: this.$thousandFormater(item.prix_vente),
        prix_revient: item.prix_revient,
        societe: undefined,
        marque: item.marque,
        numero_chassis: item.numero_chassis,
        annee: item.annee,
        couleur: item.couleur,
      };

      this.proformaForm = toForm;
      this.dialogProforma = true;
    },


    async getClients() {
      await this.$http.get("/clients").then((response) => {
        this.clients = response.data.data.reverse();
        /*    let newcli=this.clients[0];
            this.client=newcli.name
            this.client_phone=newcli.phone;*/
        this.clients.find(({ text }) => text === this.client)
        this.clients.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    async getSignataires() {
      await this.$http.get("/signataires").then((response) => {
        this.signataires = response.data.data.reverse();
        if (this.signataires != null && this.signataires.length===1){
          let newsig=this.signataires[0];
          this.signataire=newsig.name
          this.signataire_phone=newsig.phone
        }
        this.signataires.find(({ text }) => text === this.signataires)
        this.signataires.forEach((element) => {
          element.text = element.name;
        });

      });
    },

    /**
     * Fonction pour lancer le filtre
     * lorsqu'on a deux date
     */
    twoDateFiltre(arrayData, data1, data2) {
      var filtreVehiculeList = [];

      arrayData.forEach((element) => {
        var parts = element.created_at.split("-");


        var annee = parts[2];
        var mois = parts[1];
        var jour = parts[0];

        var date = annee + "-" + mois + "-" + jour;
        date.toString();

        if (
          Date.parse(date) >= Date.parse(data1) &&
          Date.parse(date) <= Date.parse(data2)
        ) {
          filtreVehiculeList.push(element);
        }
      });

      return filtreVehiculeList;
    },

    /**
     * Fonction pour lancer le filtre
     * lorsqu'on a seulement la date début
     */
    oneDateFiltre(arrayData, data) {

      console.log(arrayData)
      var filtreVehiculeList = [];

      arrayData.forEach((element) => {

        var parts = element.created_at.split("-");
        var annee = parts[2];
        var mois = parts[1];
        var jour = parts[0];

        var date = annee + "-" + mois + "-" + jour;
        date.toString();

        console.log(date)
        if (Date.parse(date) >= Date.parse(data)) {
          filtreVehiculeList.push(element);
        }
      });

      return filtreVehiculeList;
    },

    /**
     * Fonction pour lancer le filtre sur
     * un importateur sélectionné
     */
    impFiltre(arrayData, data) {
      var filtreVehiculeList = [];

      arrayData.forEach((element) => {
        if (element.importateur == data) {
          filtreVehiculeList.push(element);
        }
      });

      return filtreVehiculeList;
    },

    /**
     * Fonction pour lancer le filtre sur
     * un statut sélectionné
     */
    statutsFiltre(arrayData, data) {
      var filtreVehiculeList = [];

      arrayData.forEach((element) => {
        if (element.statut == data) {
          filtreVehiculeList.push(element);
        }
      });

      return filtreVehiculeList;
    },

    dateFiltre() {
      if (this.errorValueStart) {
        this.errorValueStart = "";
      }

      if (this.valueStart) {
        var dateFiltre = {
          date_start: this.valueStart,
          date_end: this.valueEnd,
          imp: "",
          statut: "",
        };

        console.log(dateFiltre)
        if (
          this.selectedImportateur &&
          this.selectedImportateur != "-- Importateurs --"
        ) {
          dateFiltre.imp = this.selectedImportateur;
        }

        if (this.selectedStatut && this.selectedStatut != "-- statut --") {
          dateFiltre.statut = this.selectedStatut;
        }

        if (dateFiltre.date_end) {
          var twoDateFiltre = this.twoDateFiltre(
            this.vehiculesList,
            dateFiltre.date_start,
            dateFiltre.date_end
          );

          if (dateFiltre.imp && dateFiltre.statut) {
            var impFiltre = this.impFiltre(twoDateFiltre, dateFiltre.imp);
            this.vehicules = this.statutsFiltre(impFiltre, dateFiltre.statut);
          } else if (dateFiltre.imp) {
            this.vehicules = this.impFiltre(twoDateFiltre, dateFiltre.imp);
          } else if (dateFiltre.statut) {
            this.vehicules = this.statutsFiltre(
              twoDateFiltre,
              dateFiltre.statut
            );
          } else {
            this.vehicules = twoDateFiltre;
          }
        } else {
          var onDateFiltre = this.oneDateFiltre(
            this.vehiculesList,
            dateFiltre.date_start
          );

          if (dateFiltre.imp && dateFiltre.statut) {
            var impFiltre = this.impFiltre(onDateFiltre, dateFiltre.imp);
            this.vehicules = this.statutsFiltre(impFiltre, dateFiltre.statut);
          } else if (dateFiltre.imp) {
            this.vehicules = this.impFiltre(onDateFiltre, dateFiltre.imp);
          } else if (dateFiltre.statut) {
            this.vehicules = this.statutsFiltre(
              onDateFiltre,
              dateFiltre.statut
            );
          } else {
            this.vehicules = onDateFiltre;
          }
        }
      } else if (this.valueEnd) {
        this.vehicules = this.vehiculesList;
        this.errorValueStart = "Choisissez une date de début !";
      } else {
        if (this.errorValueStart) {
          this.errorValueStart = "";
        }

        if (this.selectedStatut && this.selectedStatut != "-- statut --") {
          this.statutFiltre();
        } else if (
          this.selectedImportateur &&
          this.selectedImportateur != "-- Importateurs --"
        ) {
          this.importateurFiltre();
        } else {
          this.vehicules = this.vehiculesList;
        }
      }
    },
    resetProforma() {
      this.dialogProforma = false;
      this.proformaForm = {};
    },
    statutFiltre() {
      if (this.selectedStatut && this.selectedStatut != "-- statut --") {
        var statutFiltre = {
          imp: "",
          date_start: "",
          date_end: "",
          statut: this.selectedStatut,
        };

        if (this.valueStart) {
          statutFiltre.date_start = this.valueStart;
        }

        if (this.valueEnd) {
          statutFiltre.date_end = this.valueEnd;
        }

        if (
          this.selectedImportateur &&
          this.selectedImportateur != "-- Importateurs --"
        ) {
          statutFiltre.imp = this.selectedImportateur;
        }

        if (statutFiltre.statut) {
          var statFiltre = this.statutsFiltre(
            this.vehiculesList,
            statutFiltre.statut
          );

          if (statutFiltre.date_start && statutFiltre.imp) {
            var dateFiltre = [];

            if (statutFiltre.date_end) {
              dateFiltre = this.twoDateFiltre(
                statFiltre,
                statutFiltre.date_start,
                statutFiltre.date_end
              );
            } else {
              dateFiltre = this.oneDateFiltre(
                statFiltre,
                statutFiltre.date_start
              );
            }

            this.vehicules = this.impFiltre(dateFiltre, statutFiltre.imp);
          } else if (statutFiltre.date_start) {
            var dateFiltre = [];

            if (statutFiltre.date_end) {
              dateFiltre = this.twoDateFiltre(
                statFiltre,
                statutFiltre.date_start,
                statutFiltre.date_end
              );
            } else {
              dateFiltre = this.oneDateFiltre(
                statFiltre,
                statutFiltre.date_start
              );
            }

            this.vehicules = dateFiltre;
          } else if (statutFiltre.imp) {
            this.vehicules = this.impFiltre(statFiltre, statutFiltre.imp);
          } else {
            this.vehicules = statFiltre;
          }
        }
      } else {
        if (this.valueStart) {
          this.dateFiltre();
        } else if (
          this.selectedImportateur &&
          this.selectedImportateur != "-- Importateurs --"
        ) {
          this.importateurFiltre();
        } else {
          this.vehicules = this.vehiculesList;
        }
      }
    },

    importateurFiltre() {
      if (
        this.selectedImportateur &&
        this.selectedImportateur != "-- Importateurs --"
      ) {
        var importateurFiltre = {
          imp: this.selectedImportateur,
          date_start: "",
          date_end: "",
          statut: "",
        };

        if (this.valueStart) {
          importateurFiltre.date_start = this.valueStart;
        }

        if (this.valueEnd) {
          importateurFiltre.date_end = this.valueEnd;
        }

        if (this.selectedStatut && this.selectedStatut != "-- statut --") {
          importateurFiltre.statut = this.selectedStatut;
        }

        if (importateurFiltre.imp) {
          var impFiltre = this.impFiltre(
            this.vehiculesList,
            importateurFiltre.imp
          );

          if (importateurFiltre.date_start && importateurFiltre.statut) {
            var dateFiltre = [];

            if (importateurFiltre.date_end) {
              dateFiltre = this.twoDateFiltre(
                impFiltre,
                importateurFiltre.date_start,
                importateurFiltre.date_end
              );
            } else {
              dateFiltre = this.oneDateFiltre(
                impFiltre,
                importateurFiltre.date_start
              );
            }

            this.vehicules = this.statutsFiltre(
              dateFiltre,
              importateurFiltre.statut
            );
          } else if (importateurFiltre.date_start) {
            var dateFiltre = [];

            if (importateurFiltre.date_end) {
              dateFiltre = this.twoDateFiltre(
                impFiltre,
                importateurFiltre.date_start,
                importateurFiltre.date_end
              );
            } else {
              dateFiltre = this.oneDateFiltre(
                impFiltre,
                importateurFiltre.date_start
              );
            }

            this.vehicules = dateFiltre;
          } else if (importateurFiltre.statut) {
            this.vehicules = this.statutsFiltre(
              impFiltre,
              importateurFiltre.statut
            );
          } else {
            this.vehicules = impFiltre;
          }
        }
      } else {
        if (this.valueStart) {
          this.dateFiltre();
        } else if (
          this.selectedStatut &&
          this.selectedStatut != "-- statut --"
        ) {
          this.statutFiltre();
        } else {
          this.vehicules = this.vehiculesList;
        }
      }
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    // print vehicule modal start
    printVehicule(item) {
      this.printForm.id = item.id;
      this.printForm.numero_chassis = item.numero_chassis;
      this.printForm.montant = 2000;
      this.printVehiculeModal = true;
    },

    printCheckFormValidity() {
      const valid = this.$refs.printForm.checkValidity();
      this.nameState = valid;
      return valid;
    },
    openCard() {console.log(this.societeObjet)
      let routeData = this.$router.resolve({
        name: "importation-print-carte",
        query: this.printForm,
      });
      window.open(routeData.href, "_blank");
      this.showToast("Impression effectuée avec succès", "success");
      this.printVehiculeModal = false;
      this.getVehicules();
    },
    printHandleSubmit() {
      if (!this.printCheckFormValidity()) {
        return;
      }

      this.printForm.montant = this.$thousandFormater(this.printForm.montant);
      this.etatButtonPrintVehicule = false;
      this.printForm.societe=this.societeObjet

      this.$http
        .post("/carte-importateur/vehicules", this.printForm)
        .then((result) => {
          if (result.data.success) {
            this.etatButtonPrintVehicule = true;
            this.openCard();
            this.printForm = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
          } else {
            this.etatButtonPrintVehicule = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButtonPrintVehicule = true;
          console.log("erreur modification:", e.response.data);

          var err = e.response.data.message;

          if (err.bl) {
            this.blPrintModalErrors = err.bl[0];
            this.showToast("BL incorrect", "danger");
          }

          if (err.numero_chassis) {
            this.numero_chassisPrintModalErrors = err.numero_chassis[0];
            this.showToast("Numéro de chassis invalide", "success");
          }

          if (err.montant) {
            this.montantPrintModalErrors = err.montant[0];
            this.showToast("Veuillez entrer le montant", "success");
          }
        });
      // 	}
      // });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    printHandleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.printHandleSubmit();
    },

    blInputPrintReset() {
      if (this.blPrintModalErrors) {
        this.blPrintModalErrors = null;
      }
    },

    numeroChassisInputPrintReset() {
      if (this.numero_chassisPrintModalErrors) {
        this.numero_chassisPrintModalErrors = null;
      }
    },

    montantInputPrintReset() {
      if (this.montantPrintModalErrors) {
        this.montantPrintModalErrors = null;
      }
    },

    printModalReset() {
      this.blInputPrintReset();
      this.numeroChassisInputPrintReset();
      this.montantInputPrintReset();
    },

    printVehiculeModalReset() {
      this.printForm.id = 0;
      this.printForm.bl = "";
      this.printForm.numero_chassis = "";
      this.printForm.montant = "";
      this.nameState = null;
      this.printModalReset();
    },
    // print vehicule modal end

    // edit vehicule modal start
    editVehicule(item) {
      this.editForm.id = item.id;
      this.editForm.numero_chassis = item.numero_chassis;
      this.editForm.annee = item.annee;

      for (let i = 0; i < this.marquesList.length; i++) {
        if (this.marquesList[i].name == item.marque) {
          this.editForm.marque = this.marquesList[i].id;
          i = this.marquesList.length;
        }
      }

      for (let i = 0; i < this.couleursList.length; i++) {
        if (this.couleursList[i].name == item.couleur) {
          this.editForm.couleur = this.couleursList[i].id;
          i = this.couleursList.length;
        }
      }

      this.editVehiculeModal = true;
    },

    editCheckFormValidity() {
      const valid = this.$refs.editForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    editHandleSubmit() {
      // this.$refs.editForm.validate().then((success) => {
      // 	console.log(success)
      // 	if (success) {
      if (!this.editCheckFormValidity()) {
        return;
      }

      this.etatButtonEditVehicule = false;

      this.$http
        .put("/carte-importateur/vehicules/" + this.editForm.id, this.editForm)
        .then((result) => {
          if (result.data.success) {
            this.etatButtonEditVehicule = true;
            this.editForm = JSON.parse(
              JSON.stringify({ type: Object, default: () => {} })
            );
            this.editVehiculeModal = false;
            this.showToast("Véhicule modifié avec succès", "success");
            this.getVehicules();
          } else {
            this.etatButtonEditVehicule = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.etatButtonEditVehicule = true;
          console.log("erreur modification:", e.response.data);

          var err = e.response.data.message;

          if (err.numero_chassis) {
            console.log(err.numero_chassis[0]);
            this.numero_chassisEditModalErrors = err.numero_chassis[0];
          }

          if (err.marque) {
            console.log(err.marque[0]);
            this.marqueEditModalErrors = err.marque[0];
          }

          if (err.couleur) {
            console.log(err.couleur[0]);
            this.couleurEditModalErrors = err.couleur[0];
          }

          if (err.annee) {
            console.log(err.annee[0]);
            this.anneeEditModalErrors = err.annee[0];
          }
        });
      // 	}
      // });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    editHandleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.editHandleSubmit();
    },

    numero_chassisInputEditReset() {
      if (this.numero_chassisEditModalErrors) {
        this.numero_chassisEditModalErrors = null;
      }
    },

    marqueSelectEditReset() {
      if (this.marqueEditModalErrors) {
        this.marqueEditModalErrors = null;
      }
    },

    couleurSelectEditReset() {
      if (this.couleurEditModalErrors) {
        this.couleurEditModalErrors = null;
      }
    },

    anneeInputEditReset() {
      if (this.anneeEditModalErrors) {
        this.anneeEditModalErrors = null;
      }
    },

    editModalReset() {
      this.numero_chassisInputEditReset();
      this.marqueSelectEditReset();
      this.couleurSelectEditReset();
      this.anneeInputEditReset();
    },

    editVehiculeModalReset() {
      this.editForm.id = 0;
      this.editForm.numero_chassis = "";
      this.editForm.marque = "";
      this.editForm.couleur = "";
      this.editForm.annee = "";
      this.nameState = null;
      this.editModalReset();
    },
    // edit vehicule modal end

    // sell vehicule modal start
    sellVehicule(item) {
      this.sellForm.societe = this.societeObjet.id;
      this.sellForm.vehicule_id = item.id;
      this.sellForm.type_vente = "Transit";
      this.sellForm.vehicule = item;
      this.sellVehiculeModal = true;
    },
    optionAnnulerVente(vehicule) {
      let val = false;
      if (vehicule.facture.length > 0) {
        vehicule.facture.forEach((element) => {
          if (element.archive == false) {
            val = true;
          }
        });
        return val;
      } else return false;
    },


    changeTrie() {
      this.lastmanifestes = this.vehicules
      if(this.selectedTrie == "croissant") {
        this.vehicules = this.vehicules.reverse();
      }
      if(this.selectedTrie == "decroissant") {
      this.vehicules = this.vehicules.reverse();
      }
    },


    annulationVente(item) {
      this.vehiculecancel = item;
      let toForm = {
        id: item.id,
        prix_vente: this.$thousandFormater(item.prix_vente),
      };

      this.annulationForm = toForm;
      this.$bvModal
        .msgBoxConfirm(
          "Vous êtes sur le point d'annuler la vente de ce véhicule.",
          {
            title: "Annulation vente",
            okVariant: "success",
            cancelVariant: "outline-danger",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.cancelSold();
          } else {
            // this.dialogClient = true;
          }
        });
    },

    sellCheckFormValidity() {
      // code
    },

    sellHandleSubmit() {
      // code
    },

    parsing(amount) {
      return parseInt(amount);
    },

    sellHandleOk(bvModalEvt) {
      this.sellForm.montant_paye = this.$thousandFormater(
        this.sellForm.montant_paye
      );

      let today = new Date();
      let vehicule = this.sellForm;

      var date =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();

      let type = "";

      switch (vehicule.type_vente) {
        case "Transit":
          type = "EV";
          break;
        case "Local":
          type = "FV";
          break;
      }

      const customer = this.clientsList.find(
            (_client) => _client.id === vehicule.client.id
          );
      const operator = this.signataires.find(
            (_sig) => _sig.id === vehicule.signataire
          );
      let newifu
      if(vehicule.client_ifu===null){
        newifu="0000000000000"
      }else if(vehicule.client_ifu.length< 13){
        newifu="0000000000000"
      }
      else {
        newifu=vehicule.client_ifu
      }

      let data = {
        type: type,
        orignal: "000",
        date: date,
        reference: "-------",
        discount: 0,
        company: {
          name: this.societeObjet.name,
          address: this.societeObjet.address,
          contact: this.societeObjet.telephone,
          ifu: this.societeObjet.ifu,
        },
        operator: {
          id: this.user.id,
          name: operator?operator.name:'',
        },
        customer: {
          name: customer?customer.name:'',
          ifu: newifu,
          amount: this.sellForm.montant_paye,
          change: 0,
        },
        vat: {
          type: "A",
          value: 0,
        },
        tax: 0,
        aib: "",
        products: [
          {
            description: vehicule.vehicule.marque + "-" + vehicule.vehicule.numero_chassis,
            price: this.parsing(this.sellForm.montant_paye),
            quantity: 1,
          },
        ],
      };

      var myHeaders = new Headers();
      myHeaders.append("authorization", "Bearer bXNmOnVzZXI=");
      myHeaders.append("content-type", "application/json");
      myHeaders.append("content", JSON.stringify(data));

      var myInit = {
        method: "POST",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      };

      this.etatButtonSellVehicule = false;
      fetch("http://127.0.0.1:3440", myInit)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          // console.log(res);
          if (!res.success) {
            this.etatButtonSellVehicule = true;
            let message = 'Impossible de joindre le serveur MECEF'
            if (res.result == "No device connected")
              message = "Aucun appareil connecté";
            else if (res.result == "Device not compatible")
              message = "Appareil MECEF non compatible";
            else message = "Impossible de traiter la demande";
            this.showToast(message, "danger");
          } else {
            this.sellForm.mcf_request_content = res.result;
            this.standardizedInvoiceGenerated = res;
            this.sellCar();
          }
        })
        .catch((error) => {
          this.etatButtonSellVehicule = true;
          // console.log(error);
          this.showToast("Impossible de joindre le serveur MCF", "danger");
        });
    },

    async sellCar() {
      this.isOperating = true;
      this.sellForm.montant_paye = this.$thousandFormater(
        this.sellForm.montant_paye
      );
      console.log('My sell',this.sellForm)
      this.$http
        .post("/carte-importateur/sell-car", this.sellForm)
        .then((response) => {
          this.isOperating = false;

          if (response.data.success) {
            this.etatButtonSellVehicule = true;
            this.showToast("Vente effectuée avec succès.", "success");
            let id =
              response.data.data.facture[response.data.data.facture.length - 1]
                .id;

            this.sellVehiculeModal = false;
            let routeData = this.$router.resolve({
              name: "facture-show",
              params: { id: id },
            });
            window.open(routeData.href, "_blank");

            this.getVehicules();
          } else {
            this.etatButtonSellVehicule = true;
            this.showToast(response.data.message, "danger");
          }
        })
        .catch((err) => {
          this.etatButtonSellVehicule = true;
          // this.showToast(err.response.data.message, "danger");
        });
    },

    cancelSold() {
      let mcf_request_content = [];
      let uniqueArr = this.vehiculecancel.facture.filter(function (
        element,
        index
      ) {
        return (
          element.invoice_type === "Facture normalisée" && element.archive == 0
        );
      });
      let hasStandardizedInvoice = uniqueArr.length;

      let today = new Date();
      var dateToday =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();
      if (hasStandardizedInvoice) {
        uniqueArr.forEach((element) => {
          let mcf_response = JSON.parse(element["mcf_response"]);

          if (typeof mcf_response !== 'object') return;

          let obj = {
            type: "FA",
            original: mcf_response["nim"] + "-" + mcf_response["tc"],
            reference: "--------",
            date: dateToday,
            discount: 0,
            company: {
              name: mcf_response["companyName"],
              address: mcf_response["companyAdress"],
              contact: mcf_response["companyContact"],
            },
            operator: {
              id: mcf_response["operatorId"],
              name: mcf_response["operatorName"],
            },
            customer: {
              name: mcf_response["customerName"],
              ifu: mcf_response["customerIfu"],
              amount: mcf_response["customerAmount"],
              change: mcf_response["customerChange"],
            },
            vat: {
              type: mcf_response["items"][0]["vatType"],
              value: mcf_response["items"][0]["vat"],
            },
            tax: mcf_response["items"][0]["specificTax"],
            aib: mcf_response["aib"],
            products: [
              {
                description: mcf_response["items"][0]["name"],
                price: mcf_response["items"][0]["unitPrice"],
                quantity: mcf_response["items"][0]["quantity"],
              },
            ],
          };
          mcf_request_content.push(obj);
        });

        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer bXNmOnVzZXI=");
        myHeaders.append("content-type", "application/json");
        myHeaders.append("content", JSON.stringify(mcf_request_content));

        var myInit = {
          method: "POST",
          headers: myHeaders,
          mode: "cors",
          cache: "default",
        };
        this.isOperating = true;
        fetch("http://127.0.0.1:3440", myInit)
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            this.isOperating = false;
            if (!res.success) {
              this.standardizedInvoiceGenerated = false;
              let message = 'Veuillez lancer le serveur MECEF'
              if (res.result == "No device connected")
                message = "Aucun appareil connecté";
              else if (res.result == "Device not compatible")
                message = "Appareil MECEF non compatible";
              else message = "Veuillez lancer le serveur MECEF";
              this.showToast(message, 'danger')
            } else {
              this.annulationForm.mcf_request_content = res.result;
              this.annulationForm.mcf_response = res.result;
              this.saveAnnulation();
              this.standardizedInvoiceGenerated = true;
            }
          })
          .catch((error) => {
            // this.showToast(error, "danger");
            this.isOperating = false;
            this.standardizedInvoiceGenerated = false;
            // this.showToast("Impossible de joindre le serveur MECEF", "danger");
          });
      } else {
        this.saveAnnulation();
        this.annulationForm.invoice_type = "Facture simple";
      }
    },

    saveAnnulation() {
      this.$http
        .post("/carte-importateur/cancel-vente", this.annulationForm)
        .then((result) => {
          this.isOperating = false;
          this.getVehicules();
          if (result.data.success) {
            this.showToast("Annulation enregistrée avec succès", "success");
          } else {
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.isOperating = false;
          var err = e.response.data.message;
          // this.showToast(err, "danger");
        });
    },
    client_nameSelectSellReset(e) {
      const _id = e.target.value;
      const selected = this.clients.find(_c => _c.id == _id);
      if (selected) {
        this.sellForm.client = selected
        this.sellForm.client_reccm = selected.reccm;
				this.sellForm.client_ifu = selected.ifu;
				this.sellForm.client_phone = selected.phone;
      }
    },

    client_phoneInputSellReset() {
      if (this.sellForm.client_phone) {
			let chosen = this.clientsList.find(({ phone }) => phone === this.sellForm.client_phone);

			if (chosen) {
				this.sellForm.client = chosen.name;
				this.sellForm.client_reccm = chosen.reccm;
				this.sellForm.client_ifu = chosen.ifu;
			}
      	}
    },

    client_ifuInputSellReset() {
      // code
    },

    client_reccmInputSellReset() {
      // code
    },

    deposant_nameInputSellReset() {
      // code
    },

    deposant_phoneInputSellReset() {
      // code
    },

    signataire_nameSelectSellReset() {
        if (this.sellForm.signataire) {
            console.log(this.sellForm.signataire)
            let chosen = this.signataires.find(
                ({ text }) => text === this.sellForm.signataire
            );
            console.log(chosen)
            if (chosen) this.sellForm.signataire_phone = chosen.phone;
       }
    },

    signataire_phoneInputSellReset() {
      // code
    },

    type_venteSelectSellReset() {
      // code
    },

    montant_payeInputSellReset() {
      // code
    },

    // sell vehicule modal end

    deleteVehicule(item) {
      // 	console.log(item)

      // Message de suppression
      this.$bvModal
        .msgBoxConfirm(
          "Voulez-vous vraiment supprimer cet enregistrement de la liste des véhicules ?",
          {
            title: "Supprimer un véhicule",
            okVariant: "danger",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true) {
            this.$http
              .delete("/carte-importateur/vehicules/" + item.id)
              .then((result) => {
                if (result.data.success) {
                  // console.log(result.data.data);
                  this.showToast(
                    "Suppression effectuée avec succès",
                    "success"
                  );
                  this.getVehicules();
                } else {
                  this.showToast(result.data.message, "danger");
                }
              })
              .catch((err) => {
                this.showToast(err.response, "error");
              });
          }
        });
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.excelVehicule.forEach((element) => {
        obj = {
          "Date ARRIVÉE(S)": element.manifeste.date_arrivee,
          "Date ENREGISTREMENT(S)": element.created_at,
          "NUMERO MANIFESTE(S)": element.manifeste.numero_manif,
          "IMPORTATEUR(S)": element.importateur,
          "NUMERO CHASSIS(S)": element.numero_chassis,
          "MARQUE(S)": element.marque,
          "COULEUR(S)": element.couleur,
          "ANNEE(S)": element.annee,
          "PRIX CARTE IMPORTATEUR(S)": element.prix_carte_imp
            ? element.prix_carte_imp
            : 0,
          "STATUT(S)": element.statut,
        };

        data.push(obj);
      });

      console.log("data:");
      console.log(data);

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des véhicules",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.vehicules.forEach((element) => {
        obj = {
          "Date ARRIVÉE(S)": element.manifeste.date_arrivee,
          "Date ENREGISTREMENT(S)": element.created_at,
          "NUMERO MANIFESTE(S)": element.manifeste.numero_manif,
          "IMPORTATEUR(S)": element.importateur,
          "NUMERO CHASSIS(S)": element.numero_chassis,
          "MARQUE(S)": element.marque,
          "COULEUR(S)": element.couleur,
          "ANNEE(S)": element.annee,
          "PRIX CARTE IMPORTATEUR(S)": element.prix_carte_imp
            ? element.prix_carte_imp
            : 0,
          "STATUT(S)": element.statut,
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des véhicules",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

  },
};
</script>

<style lang="scss">
	@import "@core/scss/vue/libs/vue-wizard.scss";
	@import "@core/scss/vue/libs/vue-select.scss";

  .date-picker {
      width: 10rem !important;
    }

	#loader {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #f8f8f8;
		padding-top: 15%;
		z-index: 1000;
	}

	.b-form-date-controls {
		margin-right: 38%;
	}

	.vue-form-wizard {
		box-shadow: 0px 0px;
		margin-bottom: 0px !important;
	}

	.wizard-card-footer {
		height: 20px;
	}

	.wizard-footer-left .wizard-btn {
		//   color: #27295c !important;
		//   border: 1px solid #27295c !important;
		//   background-color: white !important;
		padding-left: 1px !important;
		padding-top: 1px !important;
		padding-right: 15px !important;
		padding-bottom: 1px !important;
	}

	.wizard-footer-right .wizard-btn {
		color: #27295c !important;
		border: 1px solid #27295c !important;
		background-color: white !important;
		padding-left: 15px !important;
		padding-top: 1px !important;
		padding-right: 0px !important;
		padding-bottom: 1px !important;
	}

	.wizard-card-footer {
		margin-bottom: 20px;
	}

	@media screen and (max-width: 600px) {
		.annuler {
			margin-bottom: 5px;
		}

		.blockSearch {
			width: 350px !important;
		}

		.search {
			width: 250px !important;
			margin-bottom: 10px;
		}
	}

	@media screen and (max-width: 800px) {
		/* .annuler {
					margin-bottom: 5px;
				} */

		.form {
			margin-left: 10%;
		}

		.formRow .form-group {
			width: 500px;
		}

		.blockForm {
			margin-left: 0px !important;
		}

		.blockSearch {
			width: 80% !important;
		}

		.search {
			width: 250px !important;
			margin-bottom: 10px;
		}

		.BlockbButton {
			padding-right: 0px !important;
			margin-right: 40px !important;
		}
	}

	@media screen and (max-width: 1000px) {
		// #solde-input {
		// 	margin-left: 20px !important;
		// }

		.form {
			margin-left: 12%;
		}

		.fonction {
			width: 520px;
		}

		.BlockbButton {
			padding-right: 130px !important;
		}
	}

	@media screen and (max-width: 700px) {
		.container-filter {
			display: block !important;
		}

		.container-search, .container-importateur,
		.container-date-start, .container-date-end,
		.container-solde {
			width: 100% !important;
			max-width: 100% !important;
			margin-right: 0px !important;
			margin-bottom: 20px;
		}

		.container-solde {
			margin-left: 0px !important;
		}

		.container-statutfilter {
			max-width: 50% !important;
			flex: initial !important;
		}
	}
</style>
